<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-container>
        <v-toolbar-title class="mb-6">Add Reminder</v-toolbar-title>
        <v-row>
          <!-- <v-col class="d-flex" cols="12" sm="5" md="5"> </v-col> -->
          <v-col cols="11" sm="4" md="4">
            <v-text-field
              v-model="reminder.value"
              label="Enter Value"
              placeholder="Enter Value"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              v-model="reminder.unit"
              label="Enter Unit"
              placeholder="Enter Unit"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="3" sm="3" md="3">
            <v-text-field
              v-model="reminder.sort"
              label="Enter Sort"
              placeholder="Enter Sort"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              elevation="2"
              large
              v-on:click="storeReminder"
              block
              v-bind="basic"
              :loading="loading"
              >Add New Reminder</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import util from "@/util/util";
export default {
  data() {
    return {
      data: {},
      rules: util.validation,
      reminder: {
        value: "",
        unit: "",
        sort: "1",
      },
      basic: util.input.basic,
      loading: false,
    };
  },
  methods: {
    storeReminder() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        console.log("reminder: ", this.reminder);
        util
          .http({ url: "reminder", method: "post", data: this.reminder })
          .then((res) => {
            this.loading = false;
            if (res.data.status == 1) {
              util.notify(1, "Reminder Stored");
              this.data = {};
              this.$emit("closeDialog", true);
              this.$emit("fetchReminder", true);
              // this.$router.push({ name: "Contacts" });
            } else {
              util.notify(0, res.data.errors[0]);
            }
          });
        // this.$emit("getParticipant", this.reminder);
        // this.$emit("closeDialog", true);
        // console.log("child: ", this.reminder);
        // this.reminder = [
        //   {
        //     email: "",
        //   },
        // ];
      }
    },
    addRow() {
      this.reminder.push({
        value: "",
        unit: "",
        sort: "1",
      });
    },
    deleteRow: function(idx) {
      this.reminder.splice(idx, 1);
    },
  },
};
</script>
<style>
.delete-row-button {
  padding-top: 8px;
}
.add_new_item {
  display: block !important;
  margin: 0 auto;
  background-color: #aaa !important;
}
</style>
